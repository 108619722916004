import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import { ITwoDiGraphInfo } from '../../../models/response/ITestInterpretation';
import { ITestInfo } from '../../../models/ITestInfo';
import { formatMessage } from '../../../translations/format-message';
import { useStore } from '../../../contexts/StoreProvider';

interface IProps {
  dataPoint: ITwoDiGraphInfo[];
  allTestInfo: ITestInfo[];
  hiddenTestId: number[];
}

const TwoDimensionalGraph = ({dataPoint, allTestInfo, hiddenTestId}: IProps) => {
    const { preferencesStore } = useStore();
    const lang = preferencesStore.getLanguage;
    
    // Function to convert SVG with color and size placeholders to base64
    const getSVGWithColorAndSize = (color: string = "#000000", size: number) => {
      const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="${size}" height="${size}">
          <path  fill="${color}" stroke="#000" stroke-width="${0}" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
      `;
      return `data:image/svg+xml;base64,${btoa(svg)}`;
    };

    const redrawCustomElements = function(abs: Highcharts.Chart) {
      const renderer = abs.renderer;
      const { plotLeft, plotTop, plotWidth, plotHeight } = abs;
  
      // Clear existing custom elements
      customElements.forEach((el: any) => el.destroy());
      customElements = [];
  
      // Draw rectangles
      customElements.push(
        renderer.rect(plotLeft, plotTop, plotWidth / 2, plotHeight / 2).attr({
          fill: '#fed5e0',
        }).add()
      );
  
      customElements.push(
        renderer.rect(plotLeft + plotWidth / 2, plotTop, plotWidth / 2, plotHeight / 2).attr({
          fill: '#fdeaa7',
        }).add()
      );
  
      customElements.push(
        renderer.rect(plotLeft, plotTop + plotHeight / 2, plotWidth / 2, plotHeight / 2).attr({
          fill: '#b4acef',
        }).add()
      );
  
      customElements.push(
        renderer.rect(plotLeft + plotWidth / 2, plotTop + plotHeight / 2, plotWidth / 2, plotHeight / 2).attr({
          fill: '#f9d5aa',
        }).add()
      );
  
      // Draw texts
      customElements.push(
        renderer.text(formatMessage('fourSideMessage_request'), plotLeft + plotWidth / 4, plotTop + plotHeight / 4).attr({
          zIndex: 5,
          align: 'center'
        }).add()
      );
  
      customElements.push(
        renderer.text(formatMessage('fourSideMessage_fact'), plotLeft + plotWidth * 3 / 4, plotTop + plotHeight / 4).attr({
          zIndex: 5,
          align: 'center'
        }).add()
      );
  
      customElements.push(
        renderer.text(formatMessage('fourSideMessage_relation'), plotLeft + plotWidth / 4, plotTop + plotHeight * 3 / 4).attr({
          zIndex: 5,
          align: 'center'
        }).add()
      );
  
      customElements.push(
        renderer.text(formatMessage('fourSideMessage_externalisationOfSelf'), plotLeft + plotWidth * 3 / 4, plotTop + plotHeight * 3 / 4).attr({
          zIndex: 5,
          align: 'center'
        }).add()
      );
  
      // Draw central rectangle
      customElements.push(
        renderer.rect(plotLeft + plotWidth / 2 - 75, plotTop + plotHeight / 2 - 50, 150, 100).attr({
          'stroke-width': 0.3,
          stroke: 'black',
          fill: 'none'
        }).add()
      );
    }
    let customElements: any = []; // To keep track of custom elements


  useEffect(() => {
    Highcharts.chart('container', {
      chart: {
        events: {
          load: function (this) {
            // Initial drawing of elements
            redrawCustomElements(this);
            // Handle resizing
            Highcharts.addEvent(this, 'resize', function () {
              redrawCustomElements(this);
            });
          }
        }
      },    
      credits: {
        enabled: false
      },
      title: {
        text: '',
        align: 'left'
      },
      accessibility: {
        landmarkVerbosity: 'one'
      },
      xAxis: {
        min: -100,
        max: 100,
        gridLineWidth: 0,
        tickInterval: 101,
        crossing: 0,
        labels: {
          enabled: false
        }
      },
      yAxis: {
        min: -100,
        max: 100,
        gridLineWidth: 0,
        tickInterval: 1,
        lineWidth: 1,
        crossing: 0,
        title: {
          text: null
        },
        labels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      series: dataPoint.filter(test => !hiddenTestId.includes(test.TestId)).map((data) => {
        const currentTest = allTestInfo.find(test => test.TestId == data.TestId); 
        return {
          type: 'scatter',
          marker: {
            enabled: true,
            radius: 8,
            // symbol: `url(https://cdn-icons-png.freepik.com/256/1077/1077114.png)`,
            // symbol: `url(https://www.svgrepo.com/show/525589/user-rounded.svg)`,
            symbol: `url(${getSVGWithColorAndSize(currentTest?.Color?.toString(), 20)})`,
            // width: 20,
            // height: 20,
            fillColor: currentTest?.Color,
            states: {
              hover: {
                  enabled: false, // Disable default hover effect
              }
            }
          },
          tooltip: {
            headerFormat: '',
            pointFormat: `<b>${`${currentTest?.FirstName && currentTest?.LastName ? currentTest.FirstName + '' + currentTest.LastName + '<br />' : ''}  ${currentTest?.UserEmail}`}</b><br><hr>`,
          },
          data: [[data.X, data.Y]]
        }
      })
    });

  }, [dataPoint, hiddenTestId, lang]);

  

  return <div id="container" className='container'/>;
};

export default TwoDimensionalGraph;
