import React, { useEffect, useState } from 'react'
import { useStore } from '../../contexts/StoreProvider';
import { TestType as TestTypeEnum} from '../../constants/enum';
import './TestLayout.css';
import TestCard from './components/TestCard';
import { observer } from 'mobx-react';
import IQuestionInfo from '../../models/response/ITestQuestion';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import RoutesConstants from '../../shared-components/Routes/routes.constants';
import Loader from '../../shared-components/Loader/Loader';
import { jwtDecode } from "jwt-decode";
import LoginRegisterPopup from './components/LoginRegisterPopup';
import LoaderButton from '../../shared-components/Button/LoaderButton';
interface IProps{
    TestType: TestTypeEnum;
}
interface DecodedToken {
    userEmail: string;
}
const TestLayout = ({TestType}: IProps) => {
    const navigate = useNavigate();
    const [ searchParams, setSearchParams] = useSearchParams();
    const eventId = searchParams.get("eventId");
    const { token } = useParams();
    const { authStore, testStore } = useStore();
    const { getAllQuestions, resetAllQuestions, resetTestInterpretation, submitTest,resetValidateTestToken, IsValidTestToken,validateTestToken,testLayoutState,allQuestionList, testUserState } = testStore;
    const { getToken, isAuthenticated, resetLogout,logoutSuccess, getEmail } =authStore;
    const [questionScore, setQuestionScore] = useState<IQuestionInfo[]>([]);
    const [isError, setIsError] = useState<boolean|undefined>(undefined);
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    let userEmail ='';
   
    useEffect(() => {
        if (allQuestionList.length > 0) {
            setQuestionScore(allQuestionList);
        }
    }, [allQuestionList]);


    useEffect(() => {
        resetAllQuestions();
        resetTestInterpretation();
        getAllQuestions(TestType,token);
    }, [TestType, isAuthenticated, getToken,token]);
   

    useEffect(() => {
        if(testUserState.success && testUserState.testId >= -1)
        if(!isAuthenticated && !getToken && token !== undefined && token !== ""){
            navigate(RoutesConstants.TestSubmission,{state:{email:userEmail}});
        }
        else{
            navigate(`${RoutesConstants.TestResult}/${testUserState.testId}`);
        }
    }, [testUserState.success])

    const handleSetQuestionScore = (questionKey: string, subQuestionKey: string, score: number) => {
        const index = questionScore.findIndex(ques => ques.Key === questionKey);
        if (index >= 0 && questionScore[index].SubQuestions)
        {
            const subQuesIndex = questionScore[index].SubQuestions.findIndex(subQues => subQues.Key === subQuestionKey)
            if (subQuesIndex >= 0)
            {
                questionScore[index].SubQuestions[subQuesIndex].Score = score;
                const isFormComplete: boolean = checkIfFormIsComplete();
                setIsError(!isFormComplete);
            }
        }
        //console.log(questionScore);
        setQuestionScore([...questionScore]);
    }

    const handleSetQuestionReply = (questionKey: string, replyKey: string) => {
        const index = questionScore.findIndex(ques => ques.Key === questionKey);
        if (index >= 0 && questionScore[index]?.OptionsList)
        {
            const replyIndex = questionScore[index].OptionsList.findIndex(reply => reply === replyKey);
            if (replyIndex >= 0)
            {
                questionScore[index].SelectedOption =[{ ReplyKey: replyKey, TestId: -1 }];
                const isFormComplete: boolean = checkIfFormIsComplete();
                setIsError(!isFormComplete);
            }
            else {
                questionScore[index].SelectedOption =[];
                const isFormComplete: boolean = checkIfFormIsComplete();
                setIsError(!isFormComplete);
            }
        }
        setQuestionScore([...questionScore]);
    }

    const checkIfFormIsComplete = (): boolean => {
        let isFormComplete: boolean = true;
        questionScore.forEach(question => {
            if (question.SubQuestions && question.SubQuestions.length > 1)
            {
                question.SubQuestions.forEach(subQues => {
                    if (Number(subQues.Score) == 0) {
                        isFormComplete = false;
                    }
                });
            }
            else if (question.OptionsList && question.OptionsList.length > 1)
            {
                const replyQuesIndexIfAnswered = question.SelectedOption.findIndex(x => x.ReplyKey !== '');
                if (replyQuesIndexIfAnswered == -1)
                {
                    isFormComplete = false;
                }
            }
        });
        return isFormComplete;
    }

    const handleSubmit = () => {
        if (isError != undefined && !isError) {
            setIsSubmitted(true);
            // Test is ready to submit
            if (!isAuthenticated && !getToken && (token === undefined || token === "")) {
                sessionStorage.setItem("testScore", JSON.stringify(questionScore));
                sessionStorage.setItem("testType", TestType.toString());
                sessionStorage.setItem("testDate", String(Math.floor(Date.now() / 1000)));
                
                // Test given using Agent's link
                if (eventId != null) {
                    sessionStorage.setItem("eventId", eventId.toString());
                    setPopupVisible(true);
                }
                else {
                    navigate(RoutesConstants.DemoTestResult);
                }        
            }
            else {
                var eventIdNumber = (eventId && parseInt(eventId)) ? Number(eventId) : undefined;
                submitTest(JSON.stringify(questionScore), TestType, token, eventIdNumber);
            }
        }
        else {
            setIsError(true);
        }
    }
    useEffect(() => {
        if (logoutSuccess) {
          resetLogout();
          navigate(RoutesConstants.Login);
        }
      }, [logoutSuccess]);

    // Test given using agent's link.
    useEffect(() => {
        if (eventId && parseInt(eventId)) {
            const score = sessionStorage.getItem("testScore");
            const testType = sessionStorage.getItem("testType");
            const testDate = sessionStorage.getItem("testDate");
            const eventId = sessionStorage.getItem("eventId");
            if (score && testType) {
                submitTest(score, Number(testType), token, Number(eventId));
            }
            sessionStorage.clear();
        }
    }, [eventId]);

    const logoutCaller = () => {
        //logout the user
        authStore.logout();
      };
      if (token) {
        try {
            const decoded = jwtDecode<DecodedToken>(token);
            // console.log(decoded)
            userEmail = decoded.userEmail;            ;
        } catch (error) {
            console.error('Invalid token', error);
        }
    }

    const handleSeeResultModalClosed = () => {
        sessionStorage.clear();
        setPopupVisible(() => false);
        setIsSubmitted(false);
    }

    return (
        <>
            {popupVisible && (
                <LoginRegisterPopup modalClosed={handleSeeResultModalClosed} isLoading={false} />
            )}

            <div className="banner--light breadcrumb-area">
                <div className="container">
                {(testLayoutState.error === "Token Expired" )?
                        <>
                            <h2><FormattedMessage id='TokenExpired_message'/></h2>
                        </>:
                        (
                        //     (testLayoutState.error !== "" ) ?
                        // <>
                        //     <h2><FormattedMessage id='logged_out_messsage'/></h2>
                        //     <Button variant="primary" data-testid="LogoutOk" onClick={logoutCaller} className="btnOkSize">
                        //         <FormattedMessage id="logout" />
                        //     </Button>
                        // </>
                        // :
                        <>
                            <h1><FormattedMessage id={TestTypeEnum[TestType]}/></h1>
                            <h2><FormattedMessage id='life_welcome_message_1' /></h2>
                        </>
                        )
                    }
                    
                    {(token && ((isAuthenticated || getToken) && ((testLayoutState.error === "" )) &&
                        <>
                            {/* <p><FormattedMessage id='life_welcome_message_2' /></p>%} */}
                            {/* we need to show the email id when test is given through trainer account */}
                            {userEmail !== getEmail && (<div className='alert alert-warning my-3 d-table mx-auto py-2' >
                                    <i className="fa fa-user me-3" style={{marginRight: '0.75rem' }}></i>
                                        {(token) ? userEmail : <FormattedMessage id='title' />}
                            </div>)
                            }
                        </>
                        ))
                    }
                    

                    {testLayoutState.error === "" && (!isAuthenticated && !getToken) &&
                    <>
                       
                        <div className='btn-group'>
                            <button className='btn btn-primary p-3' onClick={() => window.location.href = `${RoutesConstants.Login}?continue=${encodeURIComponent(window.location.href)}`}>
                                <i className="fa fa-user-check text-white me-3" ></i>
                                <FormattedMessage id='login_message' />
                            </button>
                            <button className='btn btn-secondary p-3' onClick={() => window.location.href = `${RoutesConstants.Registration}?continue=${encodeURIComponent(window.location.href)}`}>
                                <i className="fa fa-user-plus text-white me-3" ></i>
                                <FormattedMessage id='register_message' />
                            </button>
                        </div>
                        <div className='alert alert-warning my-3 d-table mx-auto py-2' >
                                <i className="fa fa-user me-3" style={{marginRight: '0.75rem' }}></i>
                                    {(token) ? userEmail : <FormattedMessage id='title' />}
                        </div>
                    </>
                    }
                </div>
            </div>
            <div className='container my-5'>
                {allQuestionList && allQuestionList.length > 0 &&
                    allQuestionList.map((question: IQuestionInfo) => {
                        if (question.SubQuestions && question.SubQuestions.length > 1)
                            return <TestCard TestType={TestType} Question={question} SetQuestionScore={handleSetQuestionScore}/>
                        else if (question.OptionsList && question.OptionsList.length > 1)
                            return <TestCard TestType={TestType} Question={question} SetQuestionReply={handleSetQuestionReply}/>
                    })
                }
                {isError &&
                    <div className="alert alert-danger mt-4">
                        <i className="fa fa-lock"></i> <FormattedMessage id='error_message'/>
                    </div>
                }
                {allQuestionList && allQuestionList.length > 0 &&
                    <div className="d-flex justify-content-center">
                        <LoaderButton className='mt-4 primary-btn' type='submit' text='submit_button' isLoading={isSubmitted} onClick={handleSubmit}/>
                    </div>
                }
            </div>
        </>
    )
}

export default observer(TestLayout);