import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ICategoryInfo } from '../../../models/response/ITestInterpretation';
import { ITestInfo } from '../../../models/ITestInfo';
import { formatMessage } from '../../../translations/format-message';
import { useStore } from '../../../contexts/StoreProvider';
interface IProps {
    dataPoint: ICategoryInfo;
    allTestInfo: ITestInfo[];
    hiddenTestId: number[];
}

const ScatterGraph3D = ({ dataPoint, allTestInfo, hiddenTestId}: IProps) => {
    const { preferencesStore } = useStore();
    const lang = preferencesStore.getLanguage;
    const [userImageSize, setUserImageSize] = useState<number>(20);
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const { testStore } = useStore();
    const { colorsAsString } = testStore;
    const [dataPointOptions, setDataPointOptions] = useState<any>();
    const initialCoordinatesOfTriangle = {
        X: 10,
        Y: 10
    }
    const deltaAdjustmentX = 0.5;    // For user icons size
    const deltaAdjustmentY = 1.5;    // For user icons size

    const getTestCoordinates = (data: number[]) => {
        const dataInScaleOfHundred = data.map((number) => number / 150 * 100);
        const a = dataInScaleOfHundred[2];
        const b = dataInScaleOfHundred[1];
        const x = initialCoordinatesOfTriangle.X + 100 - (a / 2) - b - deltaAdjustmentX;
        const y = initialCoordinatesOfTriangle.Y + (0.866 * a) + deltaAdjustmentY;
        return [x, y];
    }

    const getChartOptions = () => {
        const flattenDataToBePlotted = dataPoint.SubCategoryList.flatMap((subCat) => subCat.Results);

        // Function to convert SVG with color and size placeholders to base64
        const getSVGWithColorAndSize = (color: string = "#000000", size: number) => {
            const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="${size}" height="${size}">
                <path  fill="${color}" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
            </svg>
            `;
            return `data:image/svg+xml;base64,${btoa(svg)}`;
        };
  
        return {
            chart: {
                type: 'scatter',
                width: null,
                height: null,
                events: {
                    render:function(){
                      chartRef.current?.chart.reflow()
                    }
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: true
                    },
                    dataLabels: {
                        style: {
                            fontSize: '1em',
                            fontWeight: 'normal'
                        }
                    }
                }
            },
            title: {
                text: undefined
            },
            xAxis: {
                title: {
                    text: undefined
                },
                min: 0,
                max: 120,
                tickInterval: 20,
                lineWidth: 0,
                tickWidth: 0,
                // labels: {
                //     enabled: false
                // },
                visible: false
            },
            yAxis: {
                title: {
                    text: undefined
                },
                min: 0,
                tickInterval: 20,
                lineWidth: 0,
                tickWidth: 0,
                // labels: {
                //     enabled: false
                // },
                visible: false
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    type: 'scatter',
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 3,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    data: [
                        // [60, 96.6],
                        // [10, 10],
                        // [110, 10],
                        // [60, 96.6],
                        { x: 60, y: 96.6, name: 'A', dataLabels: { enabled: true, x: 0, y: -5, formatter: function() {return `${formatMessage(dataPoint.SubCategoryList[2].SubCategoryKey)}` }} },
                        { x: 10, y: 10, name: 'B', dataLabels: { enabled: true, x: 0, y: 30, formatter: function() {return `${formatMessage(dataPoint.SubCategoryList[1].SubCategoryKey)}` }} },
                        { x: 110, y: 10, name: 'C', dataLabels: { enabled: true, x: 0, y: 30, formatter: function() {return `${formatMessage(dataPoint.SubCategoryList[0].SubCategoryKey)}` }} },
                        { x: 60, y: 96.6 } // Closing point to complete the triangle
                    ],
                    connectEnds: true,
                    fillColor: '#fed5e0',
                    lineWidth: 1,
                    lineColor: '#121212',
                    tooltip: {
                        enabled: false
                    },
                    enableMouseTracking: false,
                },
                // Triangle series
                {
                    type: 'scatter',
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    data: [
                        // [60, 55],
                        // [50, 38],
                        // [70, 38],
                        // [60, 55]
                        [60, 47.54],
                        [52.5, 34.54],
                        [67.5, 34.54],
                        [60, 47.54]
                    ],
                    connectEnds: true,
                    color: '#FF0000',
                    lineWidth: 1,
                    lineColor: '#FF0000',
                    fillOpacity: 0.5,
                    enableMouseTracking: false,
                },
                ...allTestInfo.filter(test => !hiddenTestId.includes(test.TestId)).map((test: ITestInfo, index: number) => {
                    const dataToBePlotted = flattenDataToBePlotted.filter((res) => (res.TestId == test.TestId)).map(x => x.Result);
                    return {
                        type: "scatter",
                        name: test.UserEmail,
                        marker: {
                            enabled: true,
                            symbol: `url(${getSVGWithColorAndSize(test.Color?.toString(), userImageSize)})`,
                            // width: 20,
                            // height: 20,
                            radius: 8,
                            fillColor: test.Color,
                            states: {
                                hover: {
                                    enabled: false, // Disable default hover effect
                                }
                            }
                        },
                        tooltip: {
                            shared: true,
                            useHTML: true,
                            headerFormat: `<b>${`${test.FirstName && test.LastName ? test.FirstName + '' + test.LastName + '<br />' : ''}  ${test.UserEmail}`}</b><br><hr>`,
                            pointFormat: `
                            <b style="color: ${colorsAsString[0]}">${formatMessage(dataPoint.SubCategoryList[0].SubCategoryKey)}: ${dataToBePlotted[0]}</b><br>
                            <b style="color: ${colorsAsString[1]}">${formatMessage(dataPoint.SubCategoryList[1].SubCategoryKey)}: ${dataToBePlotted[1]}</b><br>
                            <b style="color: ${colorsAsString[2]}">${formatMessage(dataPoint.SubCategoryList[2].SubCategoryKey)}: ${dataToBePlotted[2]}</b><br>
                            `,
                        },
                        data: [
                            getTestCoordinates(dataToBePlotted)
                        ]
                    }
                })
            ]
        };
    };

    useEffect(() => {
        if (dataPoint && dataPointOptions === undefined) {
            const currentChartOptions = getChartOptions();
            setDataPointOptions(currentChartOptions);
        }
    }, [dataPoint]);

    useEffect(() => {
        const currentChartOptions = getChartOptions();
        setDataPointOptions(currentChartOptions);
        chartRef.current?.chart.reflow();
    }, [hiddenTestId, lang])


    useEffect(() => {
        let width = 0;
        let height = 0;
        const handleBeforePrint = () => {
            const chart = chartRef.current?.chart;
            if (chart) {
                width = chart.chartWidth;
                height = chart.chartHeight;
                chart.yAxis[0].options.tickPixelInterval = 80;
                chart.setSize(300, 300, false);
                chart.reflow();
            }
            setUserImageSize(() => 12);
          };
      
          const handleAfterPrint = () => {
            const chart = chartRef.current?.chart;
            if (chart) {
                chart.yAxis[0].options.tickPixelInterval = 100;
                chart.setSize(null, null, false); // Reset to default size
                chart.reflow();
            }
            setUserImageSize(() => 20);
          };
      
          window.addEventListener('beforeprint', handleBeforePrint);
          window.addEventListener('afterprint', handleAfterPrint);
      
          // Clean up the event listeners
          return () => {
            window.removeEventListener('beforeprint', handleBeforePrint);
            window.removeEventListener('afterprint', handleAfterPrint);
          };
    }, []);

    return (
        <>
            {dataPointOptions &&
                <div className='container ratio ratio-1x1 py-2'>
                    <HighchartsReact
                        className="ratio ratio-1x1"
                        highcharts={Highcharts}
                        options={dataPointOptions}
                        ref={chartRef} 
                    />
                </div>
            }
        </>
    );
};

export default ScatterGraph3D;
